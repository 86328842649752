export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"

export const ADD_LOGIN = "ADD_LOGIN"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const ADD_CUSTOMER = "ADD_CUSTOMER"
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR"
export const EDIT_CUSTOMER = "EDIT_CUSTOMER"
export const GET_CUSTOMER = "GET_CUSTOMER"
export const GET_DETAIL_CUSTOMER = "GET_DETAIL_CUSTOMER"

export const GET_PRODUCT_DASHBOARD = "GET_PRODUCT_DASHBOARD"
export const GET_PRODUCT_BUYBACK_DASHBOARD = "GET_PRODUCT_BUYBACK_DASHBOARD"

// Pusat
export const GET_BRANCH = "GET_BRANCH"
export const GET_BRANCH_SEARCH = "GET_BRANCH_SEARCH"
export const GET_BRANCH_DETAIL = "GET_BRANCH_DETAIL"
export const GET_BRANCH_ERROR = "GET_BRANCH_ERROR"
export const ADD_BRANCH = "ADD_BRANCH"
export const EDIT_BRANCH = "EDIT_BRANCH"
export const DELETE_BRANCH = "DELETE_BRANCH"

// User Manajemen
export const GET_USER = "GET_USER"
export const ADD_USER = "ADD_USER"
export const GET_DETAIL_USER = "GET_DETAIL_USER"
export const GET_USER_ROLE = "GET_USER_ROLE"

export const GET_USER_DETAIL_START = "GET_USER_DETAIL_START"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"
export const UPDATE_USER_START = "UPDATE_USER_START"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

// List VILLAGE
export const GET_VILLAGE = "GET_VILLAGE"
export const GET_VILLAGE_EDIT = "GET_VILLAGE_EDIT"

// Purchase Order
export const GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER"
export const GET_PURCHASE_ORDER_DETAIL = "GET_PURCHASE_ORDER_DETAIL"
export const UPDATE_PURCHASE_ORDER_STATUS = "UPDATE_PURCHASE_ORDER_STATUS"

// Product
export const GET_PRODUCT = "GET_PRODUCT"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const ADD_PRODUCT_START = "ADD_PRODUCT_START"
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR"
export const GET_UPDATE_PRODUCT = "GET_UPDATE_PRODUCT"
export const PATCH_UPDATE_PRODUCT = "PATCH_UPDATE_PRODUCT"
export const PATCH_UPDATE_PRODUCT_START = "PATCH_UPDATE_PRODUCT_START"
export const PATCH_UPDATE_PRODUCT_FAIL = "PATCH_UPDATE_PRODUCT_FAIL"
export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"
export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START"
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS"
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL"
export const GET_PRODUCT_START = "GET_PRODUCT_START"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL"

// Product Price
export const GET_PRODUCT_PRICE = "GET_PRODUCT_PRICE"
export const ADD_PRODUCT_PRICE = "ADD_PRODUCT_PRICE"
export const UPDATE_PRODUCT_PRICE = "UPDATE_PRODUCT_PRICE"
export const DELETE_PRODUCT_PRICE = "DELETE_PRODUCT_PRICE"

// Product Price Buyback
export const GET_PRODUCT_PRICE_BUYBACK = "GET_PRODUCT_PRICE_BUYBACK"
export const ADD_PRODUCT_PRICE_BUYBACK = "ADD_PRODUCT_PRICE_BUYBACK"
export const DELETE_PRODUCT_PRICE_BUYBACK = "DELETE_PRODUCT_PRICE_BUYBACK"

// Dashboard
export const GET_STATS_IN_CARD = "GET_STATS_IN_CARD"
export const GET_NET_INCOME_START = "GET_NET_INCOME_START"
export const GET_NET_INCOME = "GET_NET_INCOME"
export const GET_NET_INCOME_FAIL = "GET_NET_INCOME_FAIL"
export const GET_GOLD_PRICE_START = "GET_GOLD_PRICE_START"
export const GET_GOLD_PRICE = "GET_GOLD_PRICE"
export const GET_GOLD_PRICE_FAIL = "GET_GOLD_PRICE_FAIL"
export const GET_TRANSACTION_SALES_START = "GET_TRANSACTION_SALES_START"
export const GET_TRANSACTION_SALES = "GET_TRANSACTION_SALES"
export const GET_TRANSACTION_SALES_FAIL = "GET_TRANSACTION_SALES_FAIL"
export const GET_TRANSACTION_MONEY_START = "GET_TRANSACTION_MONEY_START"
export const GET_TRANSACTION_MONEY = "GET_TRANSACTION_MONEY"
export const GET_TRANSACTION_MONEY_FAIL = "GET_TRANSACTION_MONEY_FAIL"

//RESET STOCK
export const FETCH_RESET_STOCK_CABANG_START = "FETCH_RESET_STOCK_CABANG_START"
export const FETCH_RESET_STOCK_CABANG_SUCCESS = "FETCH_RESET_STOCK_CABANG_SUCCESS"
export const FETCH_RESET_STOCK_CABANG_FAIL = "FETCH_RESET_STOCK_CABANG_FAIL"
export const STORE_RESET_STOCK_CABANG_START = "STORE_RESET_STOCK_CABANG_START"
export const STORE_RESET_STOCK_CABANG_SUCCESS = "STORE_RESET_STOCK_CABANG_SUCCESS"
export const STORE_RESET_STOCK_CABANG_FAIL = "STORE_RESET_STOCK_CABANG_FAIL"

//ROUND
export const FETCH_ROUNDS_START = "FETCH_ROUNDS_START"
export const FETCH_ROUNDS_SUCCESS = "FETCH_ROUNDS_SUCCESS"
export const FETCH_ROUNDS_FAIL = "FETCH_ROUNDS_FAIL"
export const STORE_ROUND_START = "STORE_ROUND_START"
export const STORE_ROUND_SUCCESS = "STORE_ROUND_SUCCESS"
export const STORE_ROUND_FAIL = "STORE_ROUND_FAIL"
export const UPDATE_ROUND_START = "UPDATE_ROUND_START"
export const UPDATE_ROUND_SUCCESS = "UPDATE_ROUND_SUCCESS"
export const UPDATE_ROUND_FAIL = "UPDATE_ROUND_FAIL"
export const DELETE_ROUND_START = "DELETE_ROUND_START"
export const DELETE_ROUND_SUCCESS = "DELETE_ROUND_SUCCESS"
export const DELETE_ROUND_FAIL = "DELETE_ROUND_FAIL"
export const CLEAR_ERROR_ROUND = "CLEAR_ERROR_ROUND"

//QUOTA
export const FETCH_QUOTA_PRODUCTS_START = "FETCH_QUOTA_PRODUCTS_START"
export const FETCH_QUOTA_PRODUCTS_SUCCESS = "FETCH_QUOTA_PRODUCTS_SUCCESS"
export const FETCH_QUOTA_PRODUCTS_FAIL = "FETCH_QUOTA_PRODUCTS_FAIL"
export const GET_QUOTA_PRODUCT_START = "GET_QUOTA_PRODUCT_START"
export const GET_QUOTA_PRODUCT_SUCCESS = "GET_QUOTA_PRODUCT_SUCCESS"
export const GET_QUOTA_PRODUCT_FAIL = "GET_QUOTA_PRODUCT_FAIL"
export const STORE_QUOTA_PRODUCT_START = "STORE_QUOTA_PRODUCT_START"
export const STORE_QUOTA_PRODUCT_SUCCESS = "STORE_QUOTA_PRODUCT_SUCCESS"
export const STORE_QUOTA_PRODUCT_FAIL = "STORE_QUOTA_PRODUCT_FAIL"
export const UPDATE_QUOTA_PRODUCT_START = "UPDATE_QUOTA_PRODUCT_START"
export const UPDATE_QUOTA_PRODUCT_SUCCESS = "UPDATE_QUOTA_PRODUCT_SUCCESS"
export const UPDATE_QUOTA_PRODUCT_FAIL = "UPDATE_QUOTA_PRODUCT_FAIL"
export const DELETE_QUOTA_PRODUCT_START = "DELETE_QUOTA_PRODUCT_START"
export const DELETE_QUOTA_PRODUCT_SUCCESS = "DELETE_QUOTA_PRODUCT_SUCCESS"
export const DELETE_QUOTA_PRODUCT_FAIL = "DELETE_QUOTA_PRODUCT_FAIL"
export const CLEAR_ERROR_QUOTA_PRODUCT = "CLEAR_ERROR_QUOTA_PRODUCT"

//QUOTA
export const FETCH_BRANCH_PURCHASE_QUOTAS_START = "FETCH_BRANCH_PURCHASE_QUOTAS_START"
export const FETCH_BRANCH_PURCHASE_QUOTAS_SUCCESS = "FETCH_BRANCH_PURCHASE_QUOTAS_SUCCESS"
export const FETCH_BRANCH_PURCHASE_QUOTAS_FAIL = "FETCH_BRANCH_PURCHASE_QUOTAS_FAIL"
export const GET_BRANCH_PURCHASE_QUOTA_START = "GET_BRANCH_PURCHASE_QUOTA_START"
export const GET_BRANCH_PURCHASE_QUOTA_SUCCESS = "GET_BRANCH_PURCHASE_QUOTA_SUCCESS"
export const GET_BRANCH_PURCHASE_QUOTA_FAIL = "GET_BRANCH_PURCHASE_QUOTA_FAIL"
export const STORE_BRANCH_PURCHASE_QUOTA_START = "STORE_BRANCH_PURCHASE_QUOTA_START"
export const STORE_BRANCH_PURCHASE_QUOTA_SUCCESS = "STORE_BRANCH_PURCHASE_QUOTA_SUCCESS"
export const STORE_BRANCH_PURCHASE_QUOTA_FAIL = "STORE_BRANCH_PURCHASE_QUOTA_FAIL"
export const CLEAR_ERROR_BRANCH_PURCHASE_QUOTA = "CLEAR_ERROR_BRANCH_PURCHASE_QUOTA"

//PO
export const FETCH_PURCHASE_ORDERS_START = "FETCH_PURCHASE_ORDERS_START"
export const FETCH_PURCHASE_ORDERS_SUCCESS = "FETCH_PURCHASE_ORDERS_SUCCESS"
export const FETCH_PURCHASE_ORDERS_FAIL = "FETCH_PURCHASE_ORDERS_FAIL"

export const GET_PURCHASE_ORDER_START = "GET_PURCHASE_ORDER_START"
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS"
export const GET_PURCHASE_ORDER_FAIL = "GET_PURCHASE_ORDER_FAIL"

export const APPROVE_PURCHASE_ORDER_START = "APPROVE_PURCHASE_ORDER_START"
export const APPROVE_PURCHASE_ORDER_SUCCESS = "APPROVE_PURCHASE_ORDER_SUCCESS"
export const APPROVE_PURCHASE_ORDER_FAIL = "APPROVE_PURCHASE_ORDER_FAIL"

export const SENT_PURCHASE_ORDER_START = "SENT_PURCHASE_ORDER_START"
export const SENT_PURCHASE_ORDER_SUCCESS = "SENT_PURCHASE_ORDER_SUCCESS"
export const SENT_PURCHASE_ORDER_FAIL = "SENT_PURCHASE_ORDER_FAIL"

export const GET_NOTIFICATION_START = "GET_NOTIFICATION_START"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"

export const STORE_NOTIFICATION_START = "STORE_NOTIFICATION_START"
export const STORE_NOTIFICATION_SUCCESS = "STORE_NOTIFICATION_SUCCESS"
export const STORE_NOTIFICATION_FAIL = "STORE_NOTIFICATION_FAIL"

export const DOWNLOAD_PURCHASE_ORDERS_START = "DOWNLOAD_PURCHASE_ORDERS_START"
export const DOWNLOAD_PURCHASE_ORDERS_SUCCESS = "DOWNLOAD_PURCHASE_ORDERS_SUCCESS"
export const DOWNLOAD_PURCHASE_ORDERS_FAIL = "DOWNLOAD_PURCHASE_ORDERS_FAIL"

export const DOWNLOAD_PURCHASE_ORDERS_OLD_START = "DOWNLOAD_PURCHASE_ORDERS_OLD_START"
export const DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS = "DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS"
export const DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL = "DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL"