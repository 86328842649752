import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography, 
	TextField, 
    MenuItem,
    Grid,
    Paper,
    // Divider,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

// Redux
import { connect } from 'react-redux'
import { getProduct,fetchProducts } from '../../actions/product'
import { getProductPrice, deleteProductPrice } from '../../actions/productPrice'

import ListProductPrice from './ListProductPrice'
import ProductPriceBuyback from './ProductPriceBuyBack'
import {isEmpty} from "../../shared/utility";
import {Loading} from "../../components/UI";

const useStyles = makeStyles(theme => ({
	root: {
        width: '100%',
        marginTop: theme.spacing(3)
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	statusPrice: {
        width: '250px'
    },
    searchRoot: {
		padding: '4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
    },
    input: {
        margin: theme.spacing(1),
        flex: 1,
        height: 28,
    },
}));

const ProducPrice = (props) => {
	const { 
        fetchProducts,
        product : { loading, counting, productsV2,loadingV2 }
    } = props
	const classes = useStyles();
    const [ id_product, setIdProduct ] = React.useState('1')

    const handleChangeProduct = event => {
        setIdProduct(event.target.value)
    }    

	useEffect(() => {
        // getProduct('gold')
        fetchProducts('gold')
	}, [fetchProducts, counting, ])

	return (!loading || loadingV2? <Loading/> :
		<div className={classes.root}>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item>  
                        <Typography variant="h4">Manajemen Harga Produk</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                    justify='space-between'
                >
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Typography>Pilih Produk</Typography>
                        {!isEmpty(productsV2) ? (
                            <Paper component="form" className={classes.searchRoot}>
                                <TextField 
                                    select
                                    className={classes.input}
                                    name="status"
                                    value={id_product}
                                    onChange={handleChangeProduct}
                                >
                                    {productsV2.map((item) => (
                                        <MenuItem value={item.id}>{item.name} {item.weight} {item.unit}</MenuItem>
                                    ))}
                                </TextField>
                            </Paper>
                        ):(
                            <Skeleton variant="rect" height={150}></Skeleton>
                        )}
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid 
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        {!isEmpty(productsV2) ? (
                            <ListProductPrice id_product={id_product} />
                        ):(
                            <Skeleton variant="rect" height={300}></Skeleton>
                        )}
                    </Grid>
                    <Grid 
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        {!isEmpty(productsV2) ? (
                            <ProductPriceBuyback id_product={id_product} />
                        ):(
                            <Skeleton variant="rect" height={300}></Skeleton>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    productPrice: state.productPrice,
    product: state.product
})

export default connect(mapStateToProps, { getProductPrice, deleteProductPrice, getProduct, fetchProducts })(ProducPrice)