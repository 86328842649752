import React, {Fragment, useState} from "react";
import {Button, Card, CardActions, CardContent, Grid, MenuItem, TextField} from "@material-ui/core";
import UploadImage from "../UploadImage";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import SchemaValidation from "../validation";
import * as actions from "../../../../actions";
import {connect} from "react-redux";

const FormEdit = props => {
  const {
    product, classes, id, onUpdateProduct, history
  } = props;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(SchemaValidation)
  });

  const [formState, setFormState] = useState({
      name: product.name,
      weight: product.weight,
      unit: product.unit,
      type: product.type
  });

  const [ dataProduct ] = useState(product)

  const handleChange = event => {
    // console.log(event.target.value);
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const [banner, setBanner] = useState([]);
  const [base64, setB64] = useState();
  const handleChangeBanner = event => {
    setBanner(event[0]);
    let reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = function(){
      setB64(reader.result);
      // console.log(reader.result);
    }
  };

  const onSubmit = e => {
    console.log(formState, base64)
    onUpdateProduct(id, formState, base64, history)
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      defaultValue={product.name || ''}
                      label="Nama Produk"
                      margin="dense"
                      name="name"
                      onChange={handleChange}
                      helperText={
                        errors.name && errors.name.message
                      }
                      error={errors.name && true}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      defaultValue={product.weight || ''}
                      label="Berat atau Banyak"
                      margin="dense"
                      name="weight"
                      onChange={handleChange}
                      helperText={
                        errors.weight && errors.weight.message
                      }
                      error={errors.weight && true}
                      inputRef={register}
                    />
                  </Grid>

                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      defaultValue={product.unit || ''}
                      label="Unit"
                      margin="dense"
                      name="unit"
                      onChange={handleChange}
                      helperText={
                        errors.unit && errors.unit.message
                      }
                      error={errors.unit && true}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      defaultValue={product.type || ''}
                      label="Tipe Produk"
                      margin="dense"
                      name="type"
                      onChange={handleChange}
                      helperText={
                        errors.type && errors.type.message
                      }
                      error={errors.type && true}
                      inputRef={register}
                      select
                    >
                      <MenuItem key="gold" value="gold">
                        Emas
                      </MenuItem>
                      <MenuItem key="dinar" value="dinar">
                        Dinar
                      </MenuItem>
                      <MenuItem key="dirham" value="dirham">
                        Dirham
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button type="submit" size="medium" variant="contained" className={classes.btn}>
                  Simpan
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Card>
              <CardContent>
                {(
                  <UploadImage value={banner} dataProduct={dataProduct} handleChangeBanner={handleChangeBanner} />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.product.loadingV2
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProduct: (id, formData, image, history) => dispatch(actions.updateProduct(id, formData, image, history))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FormEdit);