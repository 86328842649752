
import axios from 'axios'
import axiosOrders from '../axios-orders';
import { setAlert } from './alert'
import {
    GET_PRODUCT,
    ADD_PRODUCT,
    DELETE_PRODUCT,
    GET_UPDATE_PRODUCT,
    PATCH_UPDATE_PRODUCT,
    PATCH_UPDATE_PRODUCT_START,
    PATCH_UPDATE_PRODUCT_FAIL,
    ADD_PRODUCT_START,
    ADD_PRODUCT_FAIL,
    DELETE_PRODUCT_START, DELETE_PRODUCT_FAIL
} from './types'
import * as actionTypes from "./types";

export const getProduct = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PRODUCT,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const addProduct = (formData, image, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product`
    const token = sessionStorage.getItem('access_token')
    dispatch({
        type: ADD_PRODUCT_START
    })

    const myData = {
        name : formData.name,
        weight : formData.weight,
        unit : formData.unit,
        type : formData.type,
        image : image
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_PRODUCT,
            payload: res.data
        })

        dispatch(setAlert("New ProductData Added", "success"))
        history.push(`/product`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({
            type: ADD_PRODUCT_FAIL
        })
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const delProduct = (id, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id}`
    const token = sessionStorage.getItem('access_token')
    dispatch({
        type: DELETE_PRODUCT_START
    })

    try {
        const res = await axios({
            url: endpoint,
            method: "DELETE",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: DELETE_PRODUCT,
            payload: res.data
        })

        dispatch(setAlert("ProductData Deleted", "success"))
        history.push(`/product`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({
            type: DELETE_PRODUCT_FAIL
        })
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getDetailProduct = (id) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id}`
    const token = sessionStorage.getItem('access_token')

    // dispatch({
    //     type: GET_UPDATE_PRODUCT,
    //     load: true
    // })
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_UPDATE_PRODUCT,
            payload: res.data,
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const updateProduct = (id, formData, image, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/admin/product/${id}`
    const token = sessionStorage.getItem('access_token')

    dispatch({
        type: PATCH_UPDATE_PRODUCT_START
    })

    const myData = {
        name : formData.name,
        weight : formData.weight,
        unit : formData.unit,
        type : formData.type,
        image : image
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "PATCH",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: PATCH_UPDATE_PRODUCT,
            payload: res.data
        })

        dispatch(setAlert("ProductData Edited", "success"))
        history.push(`/product`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({
            type: PATCH_UPDATE_PRODUCT_FAIL
        })
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const fetchProductsStart = () => {
    return {
        type: actionTypes.FETCH_PRODUCTS_START
    }
}

export const fetchProductsSuccess = (products) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_SUCCESS,
        products: products
    }
}

export const fetchProductsFail = (error) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_FAIL,
        error: error
    }
}

export const fetchProducts = (product_type, name) => {
    return dispatch => {
        dispatch(fetchProductsStart())
        axiosOrders.get(`admin/product/fetch?product_type=${product_type}&search=${name?name:''}`,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchProductsSuccess(res.data.data))
          })
          .catch(err => {
              dispatch(fetchProductsFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const getProductStart = () => {
    return {
        type: actionTypes.GET_PRODUCT_START
    }
}

export const getProductSuccess = (product) => {
    return {
        type: actionTypes.GET_PRODUCT_SUCCESS,
        product: product
    }
}

export const getProductFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCT_FAIL,
        error: error
    }
}

export const getProductV2 = (id) => {
    return dispatch => {
        dispatch(getProductStart())
        axiosOrders.get(`admin/product/${id}/fetch`,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(getProductSuccess(res.data.data))
          })
          .catch(err => {
              dispatch(getProductFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}